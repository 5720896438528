import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Row, Col, Container } from 'react-bootstrap';
import AgeVerification from '../components/AgeVerification/AgeVerification';
import { CookiesProvider, useCookies } from 'react-cookie';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import HeroTadDutyFree from '../components/Hero/HeroTadDutyFree';
import FooterTadDutyFree from '../components/Footer/FooterTadDutyFree';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SEO from '../components/SEO/seo';

import Image1 from '../images/tad/home1.jpeg';
import Image2 from '../images/tad/home2.jpeg';
import Image3 from '../images/tad/home3.jpeg';

import Layout from '../components/Layout/Layout';
import TadContent from '../components/TadContent/TadContent';

import 'animate.css';

const TadContentWrapper = styled.div`
  margin-top: 5rem;
  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`;

const Title = styled.h1`
  font-size: 3.525rem;
  margin: 2rem 0;
  text-align: center;
  font-weight: bold;
  color: var(--tad-red);
  font-weight: 900;
  @media (max-width: 768px) {
    font-size: 2.2rem;
  }
`;

const Text = styled.p`
  font-size: 1.325rem;
  margin: 1.325rem 0;
  text-align: left;
  color: black;
  text-align: center;
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 10rem 8rem 10rem;
  text-align: center;
  @media (max-width: 768px) {
    padding: 0 2rem 1rem 2rem;
  }
`;

const Tagline = styled.p`
  font-size: 4.325rem;
  line-height: 3.5rem;
  color: var(--tad-red);
  margin: 1.325rem 0;
  text-align: center;
  font-weight: 800;
  padding: 4rem 4rem 0 4rem;
  @media (max-width: 768px) {
    font-size: 2.925rem;
    line-height: 2.7rem;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  @media (max-width: 768px) {
    width: 100%;
    overflow: hidden;
    img {
      width: 100%;
      display: block;
    }
  }
  img:nth-child(1) {
    width: 30%;
    height: auto;
    padding: 0.5rem;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  img:nth-child(2) {
    width: 40%;
    height: auto;
    padding: 0.5rem;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  img:nth-child(3) {
    width: 30%;
    height: auto;
    padding: 0.5rem;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const TadDutyFree = ({ selectedMenu }) => {
  const { t } = useTranslation();
  const [showAgeVerification, setShowAgeVerification] = useState(true);
  const [cookies, setCookie] = useCookies(['verified']);
  const [menuSelected, setMenuSelected] = useState('home');

  const handleAgeVerification = () => {
    setCookie('verified', true, { path: '/' });
    setShowAgeVerification(false);
  };

  const handleAgeVerificationDeclined = () => {
    window.location.href = 'https://google.com';
  };

  return (
    <Layout isSubpage>
      <SEO
        title="TAD Duty Free"
        description="Your ultimate gateway to a shopping paradise that transforms your waiting hours into an adventure of discovery and indulgence before you embark on your next journey."
        image="https://optimum-filter.com/__02_IMAGE/of_facebook.png"
      />
      <Container>
        <CookiesProvider>
          <div className={`d-none ${showAgeVerification ? 'd-block' : 'd-none'}`}>
            {showAgeVerification && !cookies.verified && (
              <AgeVerification
                id="ageVerification"
                handleAgeVerification={handleAgeVerification}
                handleAgeVerificationDeclined={handleAgeVerificationDeclined}
              />
            )}
          </div>
        </CookiesProvider>
      </Container>
      <TadContent selectedMenu={'home'}>
        <TadContentWrapper>
          <Row>
            <Col md={12}>
              <TextContent className="animate__animated animate__fadeInUp">
                <Title>
                  Welcome to the exquisite world of <br />
                  TAD Duty Free
                </Title>
                <Text>
                  Your ultimate gateway to a shopping paradise that transforms your waiting hours into an adventure of
                  discovery and indulgence before you embark on your next journey.{' '}
                </Text>
                <Text>
                  At TAD Duty Free, we believe that the anticipation of a flight should be as thrilling as the journey
                  itself. That's why we've curated a luxurious retail haven, designed to offer you an unparalleled
                  shopping experience that not only complements your travel itinerary but enhances it, making your
                  pre-flight moments not just a wait, but a highlight of your travel experience.
                </Text>
              </TextContent>
            </Col>
          </Row>
          <Row>
            <Col>
              <ImageContainer>
                <img src={Image1} alt="TAD Duty Free" />
                <img src={Image2} alt="TAD Duty Free" />
                <img src={Image3} alt="TAD Duty Free" />
              </ImageContainer>
            </Col>
          </Row>
        </TadContentWrapper>
      </TadContent>
    </Layout>
  );
};

export default TadDutyFree;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
